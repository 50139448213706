import React from 'react';
import PropTypes from 'prop-types';
import Root from './src/components/common/Root';

import {
    initSessionId,
    initUtmParamsFromQueryString,
    saveLandingPageLocation,
} from './src/services/session';

// Ideally this would be wrapRootElement rather than wrapPageElement,
// but the static query in the <Root/> element won't work if so.
// If the bug referenced in https://github.com/gatsbyjs/gatsby/issues/7747
// is fixed, we can change this back to wrapRootElement
export const wrapPageElement = ({ element }) => {
    return <Root>{element}</Root>;
};

wrapPageElement.propTypes = {
    element: PropTypes.object.isRequired,
};

export const onRouteUpdate = ({ location }) => {
    initSessionId();
    initUtmParamsFromQueryString(location);
};

export const onInitialClientRender = () => {
    saveLandingPageLocation();
};

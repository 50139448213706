import UUID from 'uuid-js';

import { parseQueryString } from '../util/url';
import getWindow from '../util/window';

export const SESSION_ID_KEY = 'sessionId';
export const UTM_KEY = 'utm';
export const LANDING_PAGE_KEY = 'landingPage';

/**
 * Save all UTM parameters to session storage, so if the browser navigates away from
 * the original URL and loses the query string, we can continue to track attribution
 * on events.
 */
export function saveUtmParameters({
    utmSource,
    utmCampaign,
    utmMedium,
    utmTerm,
    utmContent,
    matchType,
    matchPosition,
    channelEntry,
}) {
    const { sessionStorage } = getWindow();
    sessionStorage.setItem(
        UTM_KEY,
        JSON.stringify({
            utmSource,
            utmCampaign,
            utmMedium,
            utmTerm,
            utmContent,
            matchType,
            matchPosition,
            channelEntry,
        })
    );
}

/**
 * Fetch previously stored UTM parameters from session storage
 */
export function getUtmParameters() {
    const { sessionStorage } = getWindow();
    const json = sessionStorage.getItem(UTM_KEY);
    if (json) {
        return JSON.parse(json);
    }
    return undefined;
}

/**
 * This is a convenience function that will pull UTM parameters
 * from query parameters and save them to session storage.
 */
export function initUtmParamsFromQueryString(location) {
    if (location && location.search) {
        const {
            utm_source: utmSource,
            utm_campaign: utmCampaign,
            utm_medium: utmMedium,
            utm_term: utmTerm,
            utm_content: utmContent,
            match_type: matchType,
            match_position: matchPosition,
            channelEntry,
        } = parseQueryString(location.search);

        if (utmSource) {
            saveUtmParameters({
                utmSource,
                utmCampaign,
                utmMedium,
                utmTerm,
                utmContent,
                matchType,
                matchPosition,
                channelEntry,
            });
        }
    }
}

/**
 * Get the session ID from session storage.
 */
export function getSessionId() {
    const { sessionStorage } = getWindow();
    return sessionStorage.getItem(SESSION_ID_KEY);
}

/**
 * This idempotent method will either fetch the existing session ID
 * or generate one and save it to session storage.
 */
export function initSessionId() {
    const { sessionStorage } = getWindow();
    let id = getSessionId();
    if (!id) {
        id = UUID.create();
        sessionStorage.setItem(SESSION_ID_KEY, id);
    }
    return id;
}

export function saveLandingPageLocation() {
    const { sessionStorage } = getWindow();
    sessionStorage.setItem(
        LANDING_PAGE_KEY,
        JSON.stringify(getWindow().location)
    );
}

export function getLandingPageLocation() {
    const { sessionStorage } = getWindow();
    const json = sessionStorage.getItem(LANDING_PAGE_KEY);
    if (json) {
        return JSON.parse(json);
    }
    return undefined;
}

import getWindow from './window';

/**
 * Add variables to the `window.global` scope for access
 * in various components. This is useful to set environment
 * specific variables that are not normally accessible at runtime.
 *
 * @param {object} globals
 */
export function addGlobals(globals) {
    const window = getWindow();
    if (window) {
        window.globals = {
            ...window.globals,
            ...globals,
        };
    }
}

export function getGlobal(key) {
    const window = getWindow();
    return window && window.globals ? window.globals[key] : undefined;
}

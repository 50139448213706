// Intersection observer (used by the Locales widet) is not available in
// all browsers, particularly IE 11 and iOS below 12.3. We'll add this
// w3c polyfill to bridge that gap.
import 'intersection-observer';

// The `URL` class is not available in IE, so we'll add a polyfill.
import 'url-polyfill';

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import baseTheme from '@serviceslabs/material-ui-pro/theme';

import { addGlobals } from '../../util/globals';
import makeTypographyStyles from '../../styles/typography';

/**
 * This component wraps the whole application with the Material UI, and
 * the @serviceslabs/material-ui-pro provided theme. It's referenced
 * in the gatsby-ssr.js and gatsby-browser.js to inject it as a root
 * wrapper for both server-side rendering as well as the client-side,
 * after rehydration.
 * @param {*} props
 */
export default function Root(props) {
    const { children, ssrMatchMedia } = props;

    const theme = createMuiTheme({
        ...baseTheme,
        ...makeTypographyStyles(baseTheme),
        overrides: {
            ...baseTheme.overrides,
            MuiInputLabel: {
                ...baseTheme.overrides.MuiInputLabel,
                root: {
                    fontSize: 16,
                },
            },
            MuiButton: {
                ...baseTheme.overrides.MuiButton,
                sizeLarge: {
                    ...(baseTheme.overrides.MuiButton.sizeLarge || {}),
                    padding: baseTheme.spacing(2, 7),
                    height: 55,
                    fontSize: '1rem',
                },
            },
        },
        props: {
            ...baseTheme.props,
            MuiUseMediaQuery: { ssrMatchMedia },
        },
    });

    /**
     * Pull stage specific data from siteMetadata that needs
     * to be put into global variables for access in the site,
     * like API URLs
     */
    const {
        site: {
            siteMetadata: { apiUrl, archesUrl, prismicRepositoryName },
        },
    } = useStaticQuery(graphql`
        query MetadataQuery {
            site {
                siteMetadata {
                    apiUrl
                    archesUrl
                    prismicRepositoryName
                }
            }
        }
    `);

    addGlobals({ apiUrl, archesUrl, prismicRepositoryName });

    // eslint-disable-next-line no-return-assign
    return (
        <>
            <Helmet>
                {/* See: https://stackoverflow.com/a/33949647/1394434 for the
                    shrink-to-fit=no explanation */}
                <meta
                    name="viewport"
                    content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
                />
            </Helmet>
            <ThemeProvider theme={theme}>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                {children}
            </ThemeProvider>
        </>
    );
}

Root.propTypes = {
    children: PropTypes.node.isRequired,
    ssrMatchMedia: PropTypes.func,
};

Root.defaultProps = {
    ssrMatchMedia: undefined,
};

export default function getWindow() {
    if (typeof window === 'undefined')
        return {
            sessionStorage: {
                getItem: () => {},
                setItem: () => {},
            },
        };
    return window;
}

export function getUserAgent() {
    const window = getWindow();
    if (window && window.navigator) {
        return window.navigator.userAgent;
    }
    return undefined;
}

export function getDocument() {
    const window = getWindow();
    return window ? window.document : {};
}

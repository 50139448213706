const FONT_STACK = 'Gibson, Roboto, Helvetica, Arial, sans-serif';

export default function makeTypographyStyles(theme) {
    return {
        typography: {
            fontFamily: FONT_STACK,
            h1: {
                fontFamily: FONT_STACK,
                fontWeight: 600,
                lineHeight: 1.2,
                [theme.breakpoints.up('md')]: {
                    fontSize: 52,
                },
                [theme.breakpoints.down('sm')]: {
                    fontSize: 32,
                },
            },
            h2: {
                fontFamily: FONT_STACK,
                fontWeight: 300,
                lineHeight: 1.3,
                [theme.breakpoints.up('md')]: {
                    fontSize: 36,
                },
                [theme.breakpoints.down('sm')]: {
                    fontSize: 24,
                },
            },
            h3: {
                fontFamily: FONT_STACK,
                fontWeight: 600,
                lineHeight: 1.4,
                [theme.breakpoints.up('md')]: {
                    fontSize: 36,
                },
                [theme.breakpoints.down('sm')]: {
                    fontSize: 24,
                },
            },
            h4: {
                fontFamily: FONT_STACK,
                fontWeight: 600,
                lineHeight: 1.4,
                [theme.breakpoints.up('md')]: {
                    fontSize: 22,
                },
                [theme.breakpoints.down('sm')]: {
                    fontSize: 16,
                },
            },
            h5: {
                fontFamily: FONT_STACK,
                fontWeight: 500,
                lineHeight: 1.2,
                [theme.breakpoints.up('md')]: {
                    fontSize: 30,
                },
                [theme.breakpoints.down('sm')]: {
                    fontSize: 22,
                },
            },
            body1: {
                fontFamily: FONT_STACK,
                fontWeight: 500,
                [theme.breakpoints.up('md')]: {
                    lineHeight: 1.4,
                    fontSize: 20,
                },
                [theme.breakpoints.down('sm')]: {
                    lineHeight: 1.3,
                    fontSize: 18,
                },
            },
            body2: {
                fontFamily: FONT_STACK,
                fontWeight: 500,
                [theme.breakpoints.up('md')]: {
                    lineHeight: 1.3,
                    fontSize: 16,
                },
                [theme.breakpoints.down('sm')]: {
                    lineHeight: 1.25,
                    fontSize: 16,
                },
            },
            subtitle1: {
                fontWeight: 500,
                // letterSpacing: 1.93,
            },
            button: {
                fontWeight: 600,
                letterSpacing: 1.17,
            },
        },
    };
}

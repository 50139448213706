export function getUrlParameter(location, name) {
    if (location && location.search) {
        const results = location.search.match(
            new RegExp(`[?&]${name}=([^&#]*)`)
        );
        // eslint-disable-next-line no-null/no-null
        return results === null
            ? undefined
            : decodeURIComponent(results[1].replace(/\+/g, ' '));
    }
    return undefined;
}

export function parseQueryString(query) {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
              .split('#')[0]
              .split('&')
              .reduce((params, param) => {
                  const [key, value] = param.split('=');
                  return value
                      ? {
                            ...params,
                            [key]: decodeURIComponent(
                                value.replace(/\+/g, ' ')
                            ),
                        }
                      : params;
              }, {})
        : {};
}

export function normalizeToUri(str) {
    if (str) {
        return encodeURIComponent(
            str.toLowerCase().replace(/[^a-zA-Z0-9]+/g, '-')
        );
    }
    return undefined;
}
